import { useRouter } from "next/router";
import Head from "next/head";

import { PreLoads } from "@/types/page";

type Props = {
	title?: string;
	metaDescription?: string;
	metaOGTitle?: string;
	metaOGDescription?: string;
	metaRobots?: boolean;
	children?: React.ReactNode;
	preloads?: PreLoads[];
};

export const _Head = ({
	title,
	metaDescription,
	metaOGTitle,
	metaOGDescription,
	metaRobots = false,
	children,
	preloads,
}: Props) => {
	const asPath = useRouter().asPath;
	return (
		<Head>
			<meta charSet="utf-8" />
			<meta name="viewport" content="width=device-width, initial-scale=1.0" />
			<link rel="preconnect" href="https://subscription.purepetfood.com" />
			<link rel="shortcut icon" type="image/png" href="/images/favicon.ico" />
			<link rel="apple-touch-icon" href="/images/favicon-192.ico" />

			{asPath === "/" ? (
				<link rel="canonical" href={"https://www.purepetfood.com"} />
			) : (
				<link rel="canonical" href={"https://www.purepetfood.com" + asPath} />
			)}

			<meta
				name="google-site-verification"
				content="DDtAmYwoRAr3ocL-JjdMzJS7BzfZpVCIF8XAi7GE64w"
			/>

			<meta
				property="og:image"
				content="https://www.datocms-assets.com/55536/1673864090-open-graph-long-1.jpg"
			/>

			{asPath === "/" ? (
				<meta property="og:url" content={"https://www.purepetfood.com"} />
			) : (
				<meta
					property="og:url"
					content={"https://www.purepetfood.com" + asPath}
				/>
			)}

			<meta name="twitter:card" content="summary" />

			<meta
				name="twitter:image"
				content="https://www.datocms-assets.com/55536/1673864094-open-graph-square-1.jpg"
			/>

			<script
				type="text/javascript"
				src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
				async
			></script>

			{/********* << CONDITIONAL RENDERING >> ***********/}

			{title && <title>{title}</title>}

			{metaDescription && <meta name="description" content={metaDescription} />}

			{metaOGTitle && <meta property="og:title" content={metaOGTitle} />}

			{metaOGDescription && (
				<meta property="og:description" content={metaOGDescription} />
			)}

			{metaRobots && <meta name="robots" content="noindex" />}

			{children}

			{preloads?.map((item) => (
				<link
					key={item.url}
					rel="preload"
					href={item.url}
					as={item.as}
					type={item.type}
				/>
			))}
		</Head>
	);
};
