import { getPetAgeLabel, shuffle } from "@/helpers/index";
import {
	recipeBenefitMap,
	ITERATION_KEYS,
	BENEFITS_ALWAYS_SHOW,
} from "@/data/recipeBenefitsHeirachy";

import { ActivityLevelTime, RecipeBenefit, RecipeOverview } from "../types";

// The recipe names can be matched off 'chicken' for example, so we just need to work out if chicken appears in the name.
const matchRecipeNameToRecipe = (name: string, recipe: string) =>
	name === recipe;

const removeRecipesNotInSuitableListForPet = (
	recipe: string,
	suitableRecipes: RecipeOverview[]
) => {
	return Boolean(
		suitableRecipes.find(({ handle }) =>
			matchRecipeNameToRecipe(handle, recipe)
		)
	);
};

export const funnelTagsSorter = (
	suitableRecipes: RecipeOverview[],
	age: number,
	activity: ActivityLevelTime,
	ailments: string[] | "none",
	goal: string[]
): RecipeOverview[] => {
	if (suitableRecipes.length === 0) {
		return [];
	}

	const filters = [
		BENEFITS_ALWAYS_SHOW,
		...(ailments === "none" ? [] : ailments),
		...goal,
		getPetAgeLabel(age),
		activity,
	].map((filter) => filter?.toLowerCase?.());

	let iterationLevel = 0;

	const suitableRecipesWithArrangedBenefitsTracker: {
		handle: string;
		benefitsToAdd: string[];
	}[] = suitableRecipes.map(({ handle }) => ({ handle, benefitsToAdd: [] }));

	// track how many of one type of benefit we've added
	const benefitsAdded: Record<string, number> = {};
	// using a while loop gives more flexibility in the future if we want to change how many items we're added based on conditions
	do {
		filters.forEach((filter) => {
			const possibleRecipes =
				recipeBenefitMap[filter]?.[ITERATION_KEYS[iterationLevel]];

			const filteredPossibleRecipes: RecipeBenefit[] | undefined =
				possibleRecipes?.filter(({ recipe }) =>
					removeRecipesNotInSuitableListForPet(recipe, suitableRecipes)
				);

			if (!filteredPossibleRecipes) {
				return;
			}

			// Save time by not shuffling if there is only 2 recipes
			const shuffledRecipes =
				filteredPossibleRecipes.length > 2
					? shuffle(filteredPossibleRecipes)
					: filteredPossibleRecipes;

			shuffledRecipes.forEach(({ recipe, benefit }) => {
				const recipeToAddBenefitTo =
					suitableRecipesWithArrangedBenefitsTracker.find(({ handle }) => {
						return matchRecipeNameToRecipe(handle, recipe);
					});
				if (!recipeToAddBenefitTo) {
					return;
				}
				if (recipeToAddBenefitTo.benefitsToAdd.length === 2) {
					// if we've added 2 to one type of recipe, skip this recipe
					return;
				}
				// if we've added 2 of the same type of benefit, skip
				if (benefitsAdded[benefit] === 2 && filter !== BENEFITS_ALWAYS_SHOW) {
					return;
				}
				// compile array of added benefits
				recipeToAddBenefitTo.benefitsToAdd = [
					...recipeToAddBenefitTo.benefitsToAdd,
					benefit,
				];
				// If we've added too many of one type of benefit...
				benefitsAdded[benefit] = benefitsAdded[benefit]
					? benefitsAdded[benefit] + 1
					: 1;
			});
		});

		// how we know when to exit
		iterationLevel++;
	} while (iterationLevel < ITERATION_KEYS.length);

	// Make a new array with the filtered benefits.
	return suitableRecipes.map(({ handle, benefits, ...recipe }) => {
		const filteredBenefits = suitableRecipesWithArrangedBenefitsTracker.find(
			({ handle: handleToAdd }) => handle === handleToAdd
		)?.benefitsToAdd;
		// If we don't have 2 benefits per recipe, find another random benefit to add.
		if (filteredBenefits?.length !== 2) {
			benefits.every((benefit) => {
				if (!filteredBenefits) {
					return false;
				}

				if (
					filteredBenefits.indexOf(benefit) > -1 ||
					benefitsAdded[benefit] === 2
				) {
					return true;
				}

				filteredBenefits.push(benefit);
				benefitsAdded[benefit] = benefitsAdded[benefit]
					? benefitsAdded[benefit] + 1
					: 1;

				return filteredBenefits.length !== 2;
			});
		}

		return {
			handle,
			...recipe,
			benefits,
			filteredBenefits,
		};
	});
};
